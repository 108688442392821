import React from "react";
import { graphql } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout";
import SEO from "../components/seo/seo-new"
import Pricing from "../components/pricing";


export const pricingQuery = graphql`
query MyQueryPricing($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
                ns
                data
                language
            }
        }
    }
}
`

export default function Pricing3({ data }) {
    const { t } = useTranslation();

    const lang = data.locales.edges[0].node.language
    const title = t("Pricing for Taxomate - Automated Bookkeeping Software")
    const description = t("Pricing for taxomate - the best and most affordable ecommerce accounting integration software.")

    return (
        <Layout>
            <SEO
                title={title}
                description={description}
                image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
                pathname='/pricing/'
                originalPathname='/pricing/'
                lang={lang}
                alternate
            />


        <Pricing />





        </Layout>
    );
}